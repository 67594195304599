import { css } from "@emotion/core";
import { mq } from "../../cssInJs";
export default {
  button: css(
    mq({
      position: "absolute",
      left: "1rem",
      lineHeight: "1.6rem",
      color: "#C2282D",
      fontSize: ["12px", null, "15px"],
    })
  ),
};
