import { css } from "@emotion/core";
import { mq } from "../../cssInJs";

export default {
  top: css(
    mq({
      height: [44, null, 82],
      background: "#fff",
      position: "fixed",
      width: "100%",
      zIndex: "999",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      transition: "all 0.3s",
    })
  ),
  logo: css(mq({})),
  logoImg: css(
    mq({
      display: "block",
      height: [40, null, 67],
    })
  ),
  logoScrolled: css(mq({})),
};
